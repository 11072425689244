@import './variables.scss';

.italic-op {
    font-style: italic;
    opacity: 0.2;
  }

  // example on Unlocked-period-list component from line 8 to 12
  .tooltip {
    display:inline-block;
    white-space: nowrap;
    position:relative;
    text-align:left;
  }

  .tooltipOnTop .bottom{
    min-width:130px;
    top:-50px !important;
    left:60%;
    transform:translate(-50%, 0);
    padding:2px 10px;
    color:$dark;
    background-color:#EEEEEE;
    font-weight:normal;
    font-size:13px;
    border-radius:8px;
    position:absolute;
    z-index:99999999;
    box-sizing:border-box;
    box-shadow:0 1px 8px rgba(0,0,0,0.5);
    visibility:hidden;
    opacity:0;
    transition:opacity 0.8s;
    transition-delay: 0.8s;
    text-align: center;
}

  .text-tooltip {
    display:inline-block;
    position:relative;
    text-align:left;
    }

  .text-tooltip .bottom {
    min-width:130px;
    top:50px;
    left:60%;
    transform:translate(-50%, 0);
    padding:2px 10px;
    color:$dark;
    background-color:#EEEEEE;
    font-weight:normal;
    font-size:13px;
    border-radius:8px;
    position:absolute;
    z-index:99999999;
    box-sizing:border-box;
    box-shadow:0 1px 8px rgba(0,0,0,0.5);
    visibility:hidden;
    opacity:0;
    transition:opacity 0.8s;
    transition-delay: 0.8s;
    text-align: center;
}

.text-tooltip:hover .bottom {
  visibility:visible; opacity:1;
  display:block;
}


  .tooltip .right {
    //min-width:150px;
    top:50%;
    left:100%;
    margin-left:20px;
    transform:translate(0, -50%);
    padding:2px 4px;
    color: $dark;
    background-color:#EEEEEE;
    font-weight:normal;
    font-size:13px;
    border-radius:8px;
    position:absolute;
    z-index:99999999;
    box-sizing:border-box;
    box-shadow:0 1px 8px rgba(0,0,0,0.5);
    visibility:hidden;
    opacity:0;
    transition:opacity 0.8s;
    transition-delay: 0.8s;
    text-align: center;
  }

  .tooltip .bottom {
    //min-width:150px;
    //min-width:70px;
    top:30px;
    left:50%;
    transform:translate(-50%, 0);
    padding:2px 10px;
    color:$dark;
    background-color:#EEEEEE;
    font-weight:normal;
    font-size:13px;
    border-radius:8px;
    position:absolute;
    z-index:99999999;
    box-sizing:border-box;
    box-shadow:0 1px 8px rgba(0,0,0,0.5);
    visibility:hidden;
    opacity:0;
    transition:opacity 0.8s;
    transition-delay: 0.8s;
    text-align: center;
}

  .tooltip:hover .right {
    visibility:visible; opacity:1;
  }

  .tooltip:hover .bottom {
      visibility:visible; opacity:1;
      display:block;
  }