.nav-btn {
  cursor: pointer;
  border-radius: 50px;
  padding: 20px 40px 20px 40px;
  font-size: inherit;
  font-weight: 700;

  @media screen and (max-width: $smallScreen) {
    padding: 10px 15px 10px 15px;
  }
}

.delete-btn-red {
  position: absolute;
  top: 10px;
  right: 5px;
  cursor: pointer;
}


.rounded-btn {
  border-radius: 20px;
  padding: 8px 22px;
  color:white
}

.circle-btn {
  display:block;
  height: 40px;
  width: 40px;
  border-radius: 50%;
}


.deviceName {
  margin-top: 20px;
  height: 30px;
  font-size: 16px;
  letter-spacing: 0px;
  font-weight: 300px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.deviceProp {
  margin-bottom: 20px;
  height: 25px;
  font-size: 12px;
  letter-spacing: 0px;
  font-weight: 300px;
}

.white-line {
  background-color: white;
  margin: 0px 0 5px 0;
}

.btn-circle {
  //background-color: $secondary!important;
  background-color: white !important;
  display: inline-block;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  width: 20px;
  height: 20px;
  border-radius: 50%;
  text-align: center;
  line-height: 10px;
  vertical-align: middle;
  padding: 20px!important;
  margin: 10px 5px;
  &:hover {
    // background-color: $secondary-dark!important;
    transform: translateY(-4px);
  }
  &:focus {
    outline: none;
  }
  @media screen and (max-width: $smallScreen) {
    transform: none !important;
  }
}


.logical-operator-btn {
  border: solid 1px;
  border-color: blue;
  border-radius: 20px;
  background-color: white;
  color: blue;
  padding: 4px 11px;
  margin: 0px 0px;
  cursor: pointer;
  transition: all 0.5s;
}


.vertical-line-left {
  position: absolute;
  width: 2px;
  background-color: blue;
  height: 40px;
  z-index: 997;
  margin-left: 100px;
  margin-top: -35px;
}

.vertical-line-right {
  position: absolute;
  width: 2px;
  background-color: blue;
  height: 40px;
  z-index: 997;
  margin-left: 200px;
  margin-top: -35px;
}


.prop-btn {
margin: 10px 0px 10px 0px;
border: none;
box-shadow: 3px 3px #E2E2E2;
font-size: inherit;
color: black;
background: lightgray;
border-radius: 5px;
cursor: pointer;
display: inline-block;
letter-spacing: 1px;
font-weight: 700;
outline: none;
position: relative;
-webkit-transition: all 0.3s;
-moz-transition: all 0.3s;
transition: all 0.3s;
  &:before {
    background-color: rgba(0, 0, 0, 0.15);
  }
  &:hover {
    transform: translateY(-3px);
    box-shadow: 0 4px 17px rgba(0, 0, 0, 0.35);
  }
  &:active {
    top: 2px;
  }
  &:after {
    content: "";
    position: absolute;
    z-index: -1;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    transition: all 0.3s;
  }
}

.prop-btn-item-text-center {
  flex-grow: 1;
  text-align: center;
  padding: 20px 0px 20px 10px;
  letter-spacing: 1px;
  font-weight: 700;
  font-family: Mulish, Arial, Helvetica, sans-serif;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.prop-btn-item-text-start {
  flex-grow: 1;
  text-align: start;
  padding: 20px 0px 20px 10px;
  letter-spacing: 1px;
  font-weight: 700;
  font-family: Mulish ,Arial, Helvetica, sans-serif;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.prop-btn-item-text-right {
  padding: 20px 10px 20px 10px;
  width: 85px;
  letter-spacing: 1px;
  font-weight: 700;
  font-family: Mulish, Arial, Helvetica, sans-serif;
}

.property-container {
  padding-top: 10px;
  padding-bottom: 10px;
  text-align: center;
  margin-bottom: 10px;
  box-shadow: 3px 3px #e2e2e2;
  background: lightgray;
  border-radius: 5px;
  cursor: pointer;
  letter-spacing: 1px;
  font-weight: 700;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  transition: all 0.3s;
  &:hover {
    transform: translateY(-3px);
    box-shadow: 0 4px 17px rgba(0, 0, 0, 0.35);
  }
  &:active {
    top: 2px;
  }
}

.width70vw {
  width: 70vw;
}

.width60vw {
  width: 60vw;
}


.width70mobile80vw {
  width: 70vw;
  @media screen and (max-width: $smallScreen) {
    width: 80vw;
  }
}

.btn-big {
  box-sizing: border-box;
  background: $primary;
  border: 1px solid #DFE0EB;
  border-radius: 8px;
  width: 100%;
  padding: 15px;
}