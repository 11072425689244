@import "src/assets/css/variables.scss";

.i-logo {
    background: transparent url('/assets/images/logo.svg') no-repeat center center;
    padding: 30px;
    margin-top: 5px;
    background-size: 65px;
    cursor: pointer;
    @media only screen and (max-width: 600px) {
        background-size: 45px;
    }
}

.i-sos-alarm {
  background: transparent url('/assets/images/sos-alarm.svg') no-repeat center center;
  padding: 20px 12px;
  background-size: 45px;
}

.i-hvac-auto {
  background: transparent url('/assets/images/hvac-auto.svg') no-repeat center center;
  padding: 10px 12px;
  background-size: 24px;
}

.i-hvac-off {
  background: transparent url('/assets/images/hvac-off.svg') no-repeat center center;
  padding: 10px 12px;
  background-size: 22px;
}

// OFFLINE

.inactive-icon {
  background-color: $offline-color !important;
  border-radius: 100%;
  position: absolute;
  top: 85px;
  left: 170px;
  background: transparent url("/assets/images/unplug_white.svg") no-repeat
    center center;
  padding: 30px 30px;
  background-size: 35px;
  z-index: 3;
}

.inactive-icon-split {
  background-color: $offline-color !important;
  border-radius: 100%;
  position: absolute;
  top: 85px;
  left: 68px;
  background: transparent url("/assets/images/unplug_white.svg") no-repeat
    center center;
  padding: 30px 30px;
  background-size: 35px;
  z-index: 3;
}

.inactive-icon-minimal {
background-color: $offline-color !important;
 border-radius: 100%;
 position: absolute;
 top: 40px;
 left: 25px;
 background: transparent url("/assets/images/unplug_white.svg") no-repeat
   center center;
 padding: 15px 15px;
 background-size: 20px;
 z-index: 3;
}

.inactive-icon-small {
  background-color: $offline-color !important;
 border-radius: 100%;
 position: absolute;
//  top: 40px;
 left: 35px;
 background: transparent url("/assets/images/unplug_white.svg") no-repeat
   center center;
 padding: 10px 10px;
 background-size: 13px;
 z-index: 3;
}


.access-control-inactive-icon {
  background-color: $offline-color !important;
  border-radius: 100%;
  position: absolute;
  top: 30px;
  left: 120px;
  background: transparent url("/assets/images/unplug_white.svg") no-repeat
    center center;
  padding: 30px 30px;
  background-size: 35px;
  z-index: 3;
}

.access-control-inactive-icon-minimal {
  background-color: $offline-color !important;
  border-radius: 100%;
  position: absolute;
  top: 21px;
  left: 25px;
  background: transparent url("/assets/images/unplug_white.svg") no-repeat
    center center;
  padding: 15px 15px;
  background-size: 20px;
  z-index: 3;
}



// NOT POLLING

.not-polling-icon {
  background-color: $not-polling-color !important;
  border-radius: 100%;
  position: absolute;
  top: 85px;
  left: 170px;
  background: transparent url("/assets/images/unplug_white.svg") no-repeat
    center center;
  padding: 30px 30px;
  background-size: 35px;
  z-index: 3;
}

.not-polling-icon-split {
  background-color: $not-polling-color !important;
  border-radius: 100%;
  position: absolute;
  top: 85px;
  left: 68px;
  background: transparent url("/assets/images/unplug_white.svg") no-repeat
    center center;
  padding: 30px 30px;
  background-size: 35px;
  z-index: 3;
}

.not-polling-icon-minimal {
background-color: $not-polling-color !important;
 border-radius: 100%;
 position: absolute;
 top: 40px;
 left: 25px;
 background: transparent url("/assets/images/unplug_white.svg") no-repeat
   center center;
 padding: 15px 15px;
 background-size: 20px;
 z-index: 3;
}


.access-control-not-polling-icon {
  background-color: $not-polling-color !important;
  border-radius: 100%;
  position: absolute;
  top: 30px;
  left: 120px;
  background: transparent url("/assets/images/unplug_white.svg") no-repeat
    center center;
  padding: 30px 30px;
  background-size: 35px;
  z-index: 3;
}

.access-control-not-polling-icon-minimal {
  background-color: $not-polling-color !important;
  border-radius: 100%;
  position: absolute;
  top: 21px;
  left: 25px;
  background: transparent url("/assets/images/unplug_white.svg") no-repeat
    center center;
  padding: 15px 15px;
  background-size: 20px;
  z-index: 3;
}

// .i-door-sm-b {
//     background: transparent url('/assets/images/door_black.svg') no-repeat center center;
//     padding: 15px;
//     background-size: 23px;
// }

// .i-door-sm-w {
//     background: transparent url('/assets/images/door_white.svg') no-repeat center center;
//     padding: 15px;
//     background-size: 23px;
// }

// .i-door-sm-gr {
//     background: transparent url('/assets/images/door.svg') no-repeat center center;
//     padding: 15px;
//     background-size: 23px;
// }


// .i-rooms-sm-w {
//     background: transparent url('/assets/images/rooms_white.svg') no-repeat center center;
//     padding: 14px 17px;
//     background-size: 23px;
//     cursor: pointer;
// }


// .i-rooms-sm-gr {
//     background: transparent url('/assets/images/rooms.svg') no-repeat center center;
//     padding: 14px 17px;
//     background-size: 23px;
//     cursor: pointer;
// }


// .i-corridor-sm-w {
//   background: transparent url('/assets/images/corridor-w.svg') no-repeat center center;
//   padding: 14px 17px;
//   background-size: 23px;
//   cursor: pointer;
// }

// .i-corridor-sm-gr {
//   background: transparent url('/assets/images/corridor-gr.svg') no-repeat center center;
//   padding: 14px 17px;
//   background-size: 23px;
//   cursor: pointer;
// }

// .i-settings-wh {
//     background: transparent url('/assets/images/settings-wh.svg') no-repeat center center;
//     padding: 14px 17px;
//     background-size: 23px;
// }

// .i-settings-gr {
//     background: transparent url('/assets/images/settings-gr.svg') no-repeat center center;
//     padding: 14px 17px;
//     background-size: 23px;
// }

// .i-key-white2 {
//   background: transparent url('/assets/images/key_white2.svg') no-repeat center center;
//   //padding: 14px 17px;
//   background-size: 8px;
// }


// .i-key-sm-w {
//     background: transparent url('/assets/images/key_white.svg') no-repeat center center;
//     padding: 14px 17px;
//     background-size: 23px;
//     cursor: pointer;
// }

// .i-key-sm-gr {
//     background: transparent url('/assets/images/key.svg') no-repeat center center;
//     padding: 14px 17px;
//     background-size: 23px;
//     cursor: pointer;
// }

// .i-users {
//     background: transparent url('/assets/images/users.svg') no-repeat center center;
//     padding: 25px;
//     margin-top: 5px;
//     background-size: 40px;
//     cursor: pointer;
// }

// .i-users-sm-w {
//   background: transparent url('/assets/images/users_white.svg') no-repeat center center;
//   padding: 14px 17px;
//   background-size: 23px;
//   cursor: pointer;
// }

// .i-users-sm-gr {
//   background: transparent url('/assets/images/users.svg') no-repeat center center;
//   padding: 14px 17px;
//   background-size: 23px;
//   cursor: pointer;
// }



// .i-list {
//     background: transparent url('/assets/images/list.svg') no-repeat center center;
//     padding: 25px;
//     margin-top: 5px;
//     background-size: 25px;
//     cursor: pointer;
// }

// .i-list-sm-w {
//     background: transparent url('/assets/images/list_white.svg') no-repeat center center;
//     padding: 15px;
//     background-size: 24px;
//     cursor: pointer;
// }

// .i-card-white {
//   background: transparent url('/assets/images/card_white.svg') no-repeat center center;
//   //padding: 14px 17px;
//   background-size: 18px;
// }

// .i-card-sm{
//     background: transparent url('/assets/images/card.svg') no-repeat center center;
//     padding: 16px;
//     background-size: 25px;
//     background-position-y: 17px;
// }

// .i-card-w {
//   background: transparent url('/assets/images/card-w.svg') no-repeat center center;
//   padding: 16px;
//   background-size: 25px;
// }

// .i-card-bl {
//   background: transparent url('/assets/images/card-bl.svg') no-repeat center center;
//   padding: 16px;
//   background-size: 25px;
// }

// .i-card-gr {
//   background: transparent url('/assets/images/card-gr.svg') no-repeat center center;
//   padding: 16px;
//   background-size: 25px;
// }

// .i-add {
//     background: transparent url('/assets/images/add.svg') no-repeat center center;
//     padding: 20px;
//     background-size: 24px;
//     &-sm {
//         @extend .i-add;
//         padding: 15px;
//         margin-top: 5px;
//         background-size: 20px;
//     }
//     &-l {
//         @extend .i-add;
//         background-size: 30px;
//     }
// }

// .i-edit {
//     background: transparent url('/assets/images/edit.svg') no-repeat center center;
//     padding: 16px;
//     background-size: 24px;
//     cursor: pointer;
//     &-sm {
//         @extend .i-edit;
//         background-position-y: 15px;
//         padding: 12px;
//         background-size: 20px;
//     }
// }

// .i-delete {
//     background: transparent url('/assets/images/delete.svg') no-repeat center center;
//     padding: 16px;
//     background-size: 28px;
//     cursor: pointer;
//     &-sm {
//         @extend .i-delete;
//         padding: 10px;
//         background-size: 24px;
//     }
//     &-xsm {
//         @extend .i-delete;
//         padding: 10px;
//         background-size: 20px;
//     }
// }

// .i-edit2 {
//   background: transparent url('/assets/images/edit2.svg') no-repeat center center;
//   padding: 16px;
//   background-size: 24px;
//   cursor: pointer;
//   &-sm {
//       @extend .i-edit;
//       background-position-y: 15px;
//       padding: 12px;
//       background-size: 20px;
//   }
// }

// .i-delete2-sm {
//   background: transparent url('/assets/images/delete2.svg') no-repeat center center;
//   cursor: pointer;
//   padding: 14px;
//   background-size: 20px;
//   }

// .i-arrow-r-xsm {//need work
//     background: transparent url('/assets/images/arrow_r.svg') no-repeat center center;
//     padding: 16px;
//     background-size: 18px;
//     cursor: pointer;
// }

// .i-arrow-up {
//     background: transparent url('/assets/images/arrow_up.svg') no-repeat center center;
//     padding: 16px;
//     background-size: 18px;
//     cursor: pointer;
// }

// .i-calendar-sm {
//     background: transparent url('/assets/images/calendar.svg') no-repeat center center;
//     padding: 16px;
//     background-size: 32px;
//     cursor: pointer;
// }


// .i-unlock {
//     background: transparent url('/assets/images/unlock.svg') no-repeat center center;
//     padding: 16px;
//     background-size: 27px;
//     cursor: pointer;
//     &-sm {
//         @extend .i-unlock;
//         background-position-y: 20px;
//         background-size: 22px;
//     }
// }

// .i-clock {
//     background: transparent url('/assets/images/clock.svg') no-repeat center center;
//     padding: 14px;
//     background-size: 22px;
//     cursor: pointer;
//     &-sm {
//         @extend .i-clock;
//         background-size: 18px;
//         padding-bottom: 17px;
//     }
// }

// .i-clock-add {
//     background: transparent url('/assets/images/clock_add.svg') no-repeat center center;
//     padding: 12px;
//     background-size: 22px;
//     cursor: pointer;
//     &-sm {
//         @extend .i-clock-add;
//         background-size: 22px;
//         padding-bottom: 17px;
//     }
// }

// .i-details-sm {
//     background: transparent url('/assets/images/details.svg') no-repeat center center;
//     padding: 16px;
//     background-size: 20px;
//     cursor: pointer;
// }


// .i-checkmark {
//   background: transparent url('/assets/images/checkmark-white.svg') no-repeat center center;
//   padding: 16px;
//   background-size: 24px;
//   cursor: pointer;
// }


// .i-reader-sm {
//     background: transparent url('/assets/images/reader.svg') no-repeat center center;
//     padding: 16px;
//     background-size: 24px
// }

// .i-reader-denied-sm {
//     background: transparent url('/assets/images/reader_deny.svg') no-repeat center center;
//     padding: 16px;
//     background-size: 24px
// }


// .i-alarm-log-sm-w {
//     background: transparent url('/assets/images/alarm_log_w.svg') no-repeat center center;
//     padding: 14px 17px;
//     background-size: 23px;
//     cursor: pointer;
// }

// .i-alarm-log-sm-gr {
//     background: transparent url('/assets/images/alarm_log.svg') no-repeat center center;
//     padding: 14px 17px;
//     background-size: 23px;
//     cursor: pointer;
// }

// .i-cold {
//   background: transparent url('/assets/images/cold.svg') no-repeat center center;
//   padding: 12px 12px;
//   background-size: 23px;
// }

// .i-fire {
//   background: transparent url('/assets/images/fire.svg') no-repeat center center;
//   padding: 12px 12px;
//   background-size: 23px;
// }

// .i-offline {
//   background: transparent url('/assets/images/offline.svg') no-repeat center center;
//   padding: 12px 12px;
//   background-size: 23px;
// }

// .i-location-sm-w {
//   background: transparent url('/assets/images/location_white.svg') no-repeat center center;
//   padding: 14px 17px;
//   background-size: 23px;
//   cursor: pointer;
// }

// .i-door-locked-status {
//     background: transparent url('/assets/images/door_locked_status.svg') no-repeat center center;
//     padding: 16px;
//     background-size: 27px;
//     &-sm {
//         @extend .i-door-locked-status;
//         background-position-y: 20px;
//         background-size: 22px;
//     }
// }

// .i-door-unlocked-status {
//     background: transparent url('/assets/images/door_unlocked_status.svg') no-repeat center center;
//     padding: 16px;
//     background-size: 27px;
//     &-sm {
//         @extend .i-door-unlocked-status;
//         background-size: 22px;
//     }
// }

// .i-door-opened-status {
//     background: transparent url('/assets/images/door_opened_status.svg') no-repeat center center;
//     padding: 16px;
//     background-size: 27px;
//     &-sm {
//         @extend .i-door-opened-status;
//         background-position-y: 20px;
//         background-size: 22px;
//     }
// }

// .i-lock-white {
//   background: transparent url('/assets/images/lock-white.svg') no-repeat center center;
//   //padding: 14px 17px;
//   background-size: 10px;
// }


// .i-safe-white {
//   background: transparent url('/assets/images/safe_white.svg') no-repeat center center;
//   //padding: 14px 17px;
//   background-size: 15px;
// }




// .i-door-closed-status {
//     background: transparent url('/assets/images/door_closed_status.svg') no-repeat center center;
//     padding: 16px;
//     background-size: 27px;
//     &-sm {
//         @extend .i-door-closed-status;
//         background-position-y: 20px;
//         background-size: 22px;
//     }
// }

// .i-transfer {
//     background: transparent url('/assets/images/transfer.svg') no-repeat center center;
//     padding: 16px;
//     background-size: 20px;
//     background-position-y: 20px;
//     cursor: pointer;
// }

// .i-user-list {
//     background: transparent url('/assets/images/user_list.svg') no-repeat center center;
//     padding: 16px;
//     background-size: 32px;
//     cursor: pointer;
// }

// .i-acknowledge {
//     background: transparent url('/assets/images/acknowledge.svg') no-repeat center center;
//     padding: 16px;
//     background-size: 32px;
//     cursor: pointer;
// }


// .i-alert-sm {
//   background: transparent url('/assets/images/alert.svg') no-repeat center center;
//   padding: 16px;
//   background-size: 15px;
// }


// .i-alert-sm-black {
//     background: transparent url('/assets/images/alert_black.svg') no-repeat center center;
//     padding: 16px;
//     background-size: 15px;
// }

// .i-back-arrow-gray{
//   background: transparent url('/assets/images/back_arrow_gray.svg') no-repeat center center;
//     padding: 16px;
//     background-size: 24px;
//     cursor: pointer;
// }

// .i-back-arrow{
//   background: transparent url('/assets/images/back_arrow.svg') no-repeat center center;
//     padding: 16px;
//     background-size: 24px;
//     cursor: pointer;
// }

// .i-forward-arrow{
//     background: transparent url('/assets/images/forward_arrow.svg') no-repeat center center;
//       padding: 16px;
//       background-size: 24px;
//       cursor: pointer;
//   }


// .i-forward-arrow-blue{
//     background: transparent url('/assets/images/forward_arrow_blue.svg') no-repeat center center;
//         padding: 16px;
//         background-size: 24px;
//         cursor: pointer;
//     }

// .i-three-vert-dots{
//   background: transparent url('/assets/images/three-vert-dots.svg') no-repeat center center;
//     padding: 16px;
//     background-size: 24px;
//     cursor: pointer;
// }


  // .i-plus-rounded-wh{
  //   background: transparent url('/assets/images/plus_wh.svg') no-repeat center center;
  //     padding: 16px;
  //     background-size: 24px;
  //     cursor: pointer;
  // }

  // .i-plus-rounded-bl{
  //   background: transparent url('/assets/images/plus_bl.svg') no-repeat center center;
  //     padding: 16px;
  //     background-size: 24px;
  //     cursor: pointer;
  // }

  // .i-sunrise {
  //   background: transparent url('/assets/images/sunrise.svg') no-repeat center center;
  //   padding: 25px;
  //   background-size: 30px;
  // }

  // .i-sunset {
  //   background: transparent url('/assets/images/sunset.svg') no-repeat center center;
  //   padding: 25px;
  //   background-size: 30px;
  // }

  // .i-noun-clock {
  //   background: transparent url('/assets/images/noun_clock.svg') no-repeat center center;
  //   padding: 25px;
  //   background-size: 30px;
  // }


// .i-access-log-sm-w {
//     background: transparent url('/assets/images/access-log.svg') no-repeat center center;
//     padding: 14px 17px;
//     background-size: 23px;
//     cursor: pointer;
// }

// .i-access-log-sm-gr {
//     background: transparent url('/assets/images/access_log_grey.svg') no-repeat center center;
//     padding: 14px 17px;
//     background-size: 23px;
//     cursor: pointer;
// }


// .i-audit-log-sm-w {
//     background: transparent url('/assets/images/audit-log.svg') no-repeat center center;
//     padding: 14px 17px;
//     background-size: 23px;
//     cursor: pointer;
// }

// .i-audit-log-sm-gr {
//     background: transparent url('/assets/images/audit_log_grey.svg') no-repeat center center;
//     padding: 14px 17px;
//     background-size: 23px;
//     cursor: pointer;
// }


// .i-refresh-sm {
//     background: transparent url('/assets/images/refresh.svg') no-repeat center center;
//     padding: 15px;
//     background-size: 23px;
//     cursor: pointer;
// }

// .i-reset-sm {
//     background: transparent url('/assets/images/reset.svg') no-repeat center center;
//     padding: 15px;
//     background-size: 25px;
//     cursor: pointer;
// }

// .i-x-xsm {
//     background: transparent url('/assets/images/x.svg') no-repeat center center;
//     padding: 14px 17px;
//     background-size: 12px;
//     cursor: pointer;
// }

// .i-live-log-sm-w {
//   background: transparent url('/assets/images/live-log.svg') no-repeat center center;
//   padding: 14px 17px;
//   background-size: 23px;
//   cursor: pointer;
// }

// .i-live-log-sm-gr {
//     background: transparent url('/assets/images/live_log_grey.svg') no-repeat center center;
//     padding: 14px 17px;
//     background-size: 23px;
//     cursor: pointer;
// }

// .i-alarm-sm-gr {
//     background: transparent url('/assets/images/alarm.svg') no-repeat center center;
//     padding: 14px 17px;
//     background-size: 23px;
//     cursor: pointer;
// }

// .i-cleaning-clean {
//   background: transparent url('/assets/images/cleaning-clean.svg') no-repeat center center;
//   padding: 18px 10px;
//   background-size: 20px;
// }

// .i-cleaning-inspected {
//   background: transparent url('/assets/images/cleaning-inspected.svg') no-repeat center center;
//   padding: 18px 10px;
//   background-size: 20px;
// }

// .i-cleaning-failed-ispection {
//   background: transparent url('/assets/images/cleaning-failed-ispection.svg') no-repeat center center;
//   padding: 18px 10px;
//   background-size: 20px;
// }

// .i-cleaning-in-progress {
//   background: transparent url('/assets/images/cleaning-in-progress.svg') no-repeat center center;
//   padding: 18px 10px;
//   background-size: 20px;
// }

// .i-cleaning-none {
//   background: transparent url('/assets/images/cleaning-none.svg') no-repeat center center;
//   padding: 18px 10px;
//   background-size: 20px;
// }

// .delete-icon {
//   background: transparent url('/assets/images/clear.svg') no-repeat center center;
//   padding: 16px;
//   background-size: 22px;
//   margin-left: -32px;
//   margin-top:2px;
//   opacity: 0.4;
// }

// .delete-icon:hover{
//   opacity: 1;
//   cursor: pointer;
// }


// .i-dnd-red {
//   background: transparent url('/assets/images/dnd-red.svg') no-repeat center center;
//   padding: 19px 10px;
//   background-size: 20px;
// }

// .i-dnd-grey {
//   background: transparent url('/assets/images/dnd-grey.svg') no-repeat center center;
//   padding: 19px 10px;
//   background-size: 20px;
// }

// .i-forbidden-white {
//   background: transparent url('/assets/images/forbidden_white.svg') no-repeat center center;
//   //padding: 19px 10px;
//   background-size: 15px;
// }

// .i-mur-white {
//   background: transparent url('/assets/images/broom_wh.svg') no-repeat center center;
//   //padding: 19px 10px;
//   background-size: 14px;
// }

// .i-inspect-white {
//   background: transparent url('/assets/images/inspect_white.svg') no-repeat center center;
//   //padding: 19px 10px;
//   background-size: 16px;
// }

// .i-fan-white {
//   background: transparent url('/assets/images/fan_white.svg') no-repeat center center;
//   //padding: 19px 10px;
//   background-size: 16px;
// }

// .i-clean-white {
//   background: transparent url('/assets/images/cleaned_white.svg') no-repeat center center;
//   //padding: 19px 10px;
//   background-size: 16px;
// }

// .i-alarm-bell-white {
//   background: transparent url('/assets/images/alarm_bell_wh.svg') no-repeat center center;
//   //padding: 19px 10px;
//   background-size: 24px;
// }


// .i-minibar-red {
//   background: transparent url('/assets/images/minibar-red.svg') no-repeat center center;
//   padding: 20px 12px;
//   background-size: 18px;
// }

// .i-minibar-gray {
//   background: transparent url('/assets/images/minibar-gray.svg') no-repeat center center;
//   padding: 20px 12px;
//   background-size: 18px;
// }

// .i-minibar-white {
//   background: transparent url('/assets/images/minibar-white.svg') no-repeat center center;
//   // padding: 20px 12px;
//   background-size: 10px;
// }

// .i-room-status-none {
//   background: transparent url('/assets/images/room-status-none.svg') no-repeat center center;
//   padding: 20px 17px;
//   background-size: 25px;
// }

// .i-room-status-clean {
//   background: transparent url('/assets/images/room-status-clean.svg') no-repeat center center;
//   padding: 20px 17px;
//   background-size: 25px;
// }


// .i-room-status-dirty {
//   background: transparent url('/assets/images/room-status-dirty.svg') no-repeat center center;
//   padding: 20px 17px;
//   background-size: 25px;
// }


// .i-room-status-inspected {
//   background: transparent url('/assets/images/room-status-inspected.svg') no-repeat center center;
//   padding: 20px 17px;
//   background-size: 25px;
// }


// .i-room-status-touched {
//   background: transparent url('/assets/images/room-status-touched.svg') no-repeat center center;
//   padding: 20px 17px;
//   background-size: 25px;
// }

// .i-welcome-light-off {
//   background: transparent url('/assets/images/welcome-light-off.svg') no-repeat center center;
//   padding: 20px 10px;
//   background-size: 20px;
// }

// .i-welcome-light-on {
//   background: transparent url('/assets/images/welcome-light-on.svg') no-repeat center center;
//   padding: 20px 10px;
//   background-size: 20px;
// }

// .i-welcome-light-white {
//   background: transparent url('/assets/images/welcome-light-white.svg') no-repeat center center;
//   // padding: 20px 10px;
//   background-size: 10px;
// }

// .i-sun-and-moon-gray {
//   background: transparent url('/assets/images/moon_and_sun_gray.svg') no-repeat center center;
//   padding: 20px 10px;
//   background-size: 32px;
// }

// .i-sun-and-moon-yellow {
//   background: transparent url('/assets/images/moon_and_sun_yellow.svg') no-repeat center center;
//   padding: 20px 10px;
//   background-size: 32px;
// }

// .i-copy {
//   background: transparent url('/assets/images/copy.svg') no-repeat center center;
//   padding: 15px 15px;
//   background-size: 32px;
// }


// .i-paste {
//   background: transparent url('/assets/images/paste.svg') no-repeat center center;
//   padding: 8px 8px;
//   background-size: 22px;
// }


// .i-room-not-armed {
//   background: transparent url('/assets/images/room-not-armed.svg') no-repeat center center;
//   padding: 20px 12px;
//   background-size: 25px;
// }

// .i-room-armed {
//   background: transparent url('/assets/images/room-armed.svg') no-repeat center center;
//   padding: 20px 12px;
//   background-size: 25px;
// }

// .i-room-armed-white {
//   background: transparent url('/assets/images/room-armed-white.svg') no-repeat center center;
//   // padding: 20px 12px;
//   background-size: 15px;
// }


// .i-safe-black {
//   background: transparent url('/assets/images/safe-black.svg') no-repeat center center;
//   padding: 20px 15px;
//   background-size: 25px;
// }


// .i-safe-gray {
//   background: transparent url('/assets/images/safe-gray.svg') no-repeat center center;
//   padding: 20px 15px;
//   background-size: 25px;
// }

// .i-safe-red {
//   background: transparent url('/assets/images/safe-red.svg') no-repeat center center;
//   padding: 12px 12px;
//   background-size: 20px;
// }

// .search-icon {
//   background: transparent url('/assets/images/search.svg') no-repeat center center;
//   padding: 16px;
//   background-size: 22px;
//   margin-left: -30px;
//   margin-top:7px;
// }

// .i-door-open {
//   background: transparent url('/assets/images/door_opened_status.svg') no-repeat center center;
//   padding: 20px 12px;
//   background-size: 25px;
// }

// .i-door-closed {
//   background: transparent url('/assets/images/door_closed_status.svg') no-repeat center center;
//   padding: 20px 12px;
//   background-size: 25px;
// }

// .i-door-white {
//   background: transparent url('/assets/images/door-white.svg') no-repeat center center;
//   // padding: 20px 12px;
//   background-size: 22px;
// }


// .i-door-locked {
//   background: transparent url('/assets/images/door_locked_status.svg') no-repeat center center;
//   padding: 20px 13px;
//   background-size: 25px;
// }

// .i-door-unlocked {
//   background: transparent url('/assets/images/door_unlocked_status.svg') no-repeat center center;
//   padding: 20px 13px;
//   background-size: 25px;
// }


// .i-window-open {
//   background: transparent url('/assets/images/window-open.svg') no-repeat center center;
//   padding: 20px 12px;
//   background-size: 20px;
// }

// .i-window-closed {
//   background: transparent url('/assets/images/window-closed.svg') no-repeat center center;
//   padding: 20px 12px;
//   background-size: 20px;
// }

// .i-window-white {
//   background: transparent url('/assets/images/window-white.svg') no-repeat center center;
//   // padding: 20px 12px;
//   background-size: 10px;
// }

// .i-battery-black {
//   background: transparent url('/assets/images/battery-black.svg') no-repeat center center;
//   padding: 20px 13px;
//   background-size: 15px;
// }


// .i-battery-gray {
//   background: transparent url('/assets/images/battery-gray.svg') no-repeat center center;
//   padding: 20px 12px;
//   background-size: 15px;
// }

// .i-battery-white {
//   background: transparent url('/assets/images/battery-white.svg') no-repeat center center;
//   // padding: 20px 12px;
//   background-size: 10px;
// }


// .i-hvac-low {
//   background: transparent url('/assets/images/hvac-low.svg') no-repeat center center;
//   padding: 10px 12px;
//   background-size: 15px;
// }


// .i-hvac-mid {
//   background: transparent url('/assets/images/hvac-mid.svg') no-repeat center center;
//   padding: 10px 12px;
//   background-size: 15px;
// }

// .i-hvac-high {
//   background: transparent url('/assets/images/hvac-high.svg') no-repeat center center;
//   padding: 10px 12px;
//   background-size: 15px;
// }

// .i-hvac-cool {
//   background: transparent url('/assets/images/snowflake_white.svg') no-repeat center center;
//   // padding: 10px 12px;
//   background-size: 15px;
// }

// .i-temp-white {
//   background: transparent url('/assets/images/temp_white.svg') no-repeat center center;
//   // padding: 10px 12px;
//   background-size: 8px;
// }

// .i-temp-target-white {
//   background: transparent url('/assets/images/temp_target_white.svg') no-repeat center center;
//   // padding: 10px 12px;
//   background-size: 8px;
// }

// .i-bathroom-wh {
//   background: transparent url('/assets/images/bathroom_wh.svg') no-repeat center center;
//   padding: 20px 12px;
//   background-size: 25px;
// }

// .i-room-wh {
//   background: transparent url('/assets/images/room_wh.svg') no-repeat center center;
//   padding: 13px 12px;
//   background-size: 25px;
// }


// .i-radiator-black {
//   background: transparent url('/assets/images/radiator-black.svg') no-repeat center center;
//   padding: 10px 12px;
//   background-size: 16px;
// }

// .i-radiator-gray {
//   background: transparent url('/assets/images/radiator-gray.svg') no-repeat center center;
//   padding: 10px 12px;
//   background-size: 16px;
// }



// .i-flood-alarm {
//   background: transparent url('/assets/images/flood-alarm.svg') no-repeat center center;
//   padding: 10px 12px;
//   background-size: 20px;
// }

// .i-temperature-difference-alarm {
//   background: transparent url('/assets/images/temperature-difference-alarm.svg') no-repeat center center;
//   padding: 12px 12px;
//   background-size: 16px;
// }

// .i-filter-bl {
//   background: transparent url('/assets/images/filter_bl.svg') no-repeat center center;
//   padding: 17px;
//   background-size: 24px;
//   cursor: pointer;
// }

// .i-language-bubble {
//   background: transparent url('/assets/images/language_bubble.svg') no-repeat center center;
//   padding: 20px 10px 20px 10px;
//   background-size: 22px;
// }

// .i-automation-sm-gr {
//     background: transparent url('/assets/images/automation_gr.svg') no-repeat center center;
//     padding: 16px;
//     background-size: 32px;
//     cursor: pointer;
// }

// .i-automation-sm-wh {
//   background: transparent url('/assets/images/automation_wh.svg') no-repeat center center;
//   padding: 16px;
//   background-size: 32px;
//   cursor: pointer;
// }

// .i-lightbulb-gray {
//   background: transparent url('/assets/images/lightbulb-gray.svg') no-repeat center center;
//   padding: 18px;
//   background-size: 22px;
// }


// .i-play-bl {
//   background: transparent url('/assets/images/play_bl.svg') no-repeat center center;
//   padding: 15px;
//   background-size: 40px;
//   cursor: pointer;
// }

// .btn-full{
//   background: transparent url('/assets/images/full.svg') no-repeat center center;
//   padding: 13px;
//   background-size: 28px;
//   cursor: pointer;
//   }

//   .btn-full-gray{
//     background: transparent url('/assets/images/full_gray.svg') no-repeat center center;
//     padding: 13px;
//     background-size: 28px;
//     cursor: pointer;
//     }

  // .btn-compact {
  //   background: transparent url('/assets/images/compact.svg') no-repeat center center;
  //   padding: 13px;
  //   background-size: 28px;
  //   cursor: pointer;
  // }

  // .btn-compact-gray {
  //   background: transparent url('/assets/images/compact_gray.svg') no-repeat center center;
  //   padding: 13px;
  //   background-size: 28px;
  //   cursor: pointer;
  // }

  // .btn-minimal {
  //   background: transparent url('/assets/images/minimal.svg') no-repeat center center;
  //   padding: 13px;
  //   background-size: 28px;
  //   cursor: pointer;
  // }

  // .btn-minimal-gray {
  //   background: transparent url('/assets/images/minimal_gray.svg') no-repeat center center;
  //   padding: 13px;
  //   background-size: 28px;
  //   cursor: pointer;
  // }
