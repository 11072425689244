.door-group-included-container {
  width: 50%;
  text-align: center;
  margin-right: 60px;
  align-self: normal;
}

.door-group-available-container {
  width: 50%;
  text-align: center;
  margin-left: 60px;
  align-self: normal;
}

.door-group-door {
  border-bottom: 1px solid lightgrey;
  padding: 2px 6px;
  margin: 10px;
  cursor: default;
}

.doors-in-group-container {
max-height: 300px;
overflow-y: auto;
overflow-x: hidden;
padding:5px 0px
}
