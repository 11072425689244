.items-grid-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(72px, max-content));
  justify-content: flex-start;
}

.single-grid-item-container {
  margin: 10px 5px;
  cursor: pointer;
  border-radius: 50px;
  display: flex;
  transition: all 0.3s;
  flex-direction: column;
  width: 90px;

  align-items: center;
  background: white;
  color: black;
  font-weight: normal;

  &:active {
    transform: translateY(2px);
  }
}

.grid-item-icon {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  transition: all 0.3s;
  background-color: $secondary //!important;
}

.grid-item-icon-text {
  font-size: smaller;
  text-align: center;
  padding: 0px;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}


.container-card {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  margin-top: 20px;
  margin: 10px 5px;
  border-radius: 10px;
}

.card-title {
  font-size: 1.2rem;
  font-weight: bold;
  margin: 10px;
  color: $ocean-blue;
  text-align: center;
}


.icon-selected {
  background-color: $ocean-blue;
}

.icon-not-selected {
  background-color: $secondary;
}


.main-icon {
  // border: 1px solid black;
  width: 82px;
  height: 82px;
  border-radius: 20px;
  box-shadow: 0 4px 17px rgba(0, 0, 0, 0.35);
}
