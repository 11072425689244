.permission-container {
    max-height: 300px;
    overflow-y: auto;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-items: flex-start;
    justify-content: center;
    padding: 0px 20px 20px 20px;
    transition: max-height 1s ease-out;
    &-no-scroll {
       max-height: none;
       overflow: hidden;
    }

    @media screen and (max-width: 1100px) {
        margin-left: 0px;
        width: 100%;
    }
    @media screen and (max-width: 400px) {
        margin-left: 0px;
        width: 100%;
        overflow-y: unset;
        max-height: unset;
        max-width: unset;
    }
}

.space-around {
    justify-content: space-around;
}

.permission-header-title {
    min-width: 200px;
}
.permission {
    min-width: 280px;
    min-height: 40px;
    width: 390px;
    margin: 20px;
    border-bottom: 1px solid $secondary-dark;
    &-text {
        width: 130px;
    }
    &-name {
        width: 120px;
    }
    &-checkbox {
        width: 155px;
    }
    &-rule {
        width: 40px;
    }
    @media screen and (max-width: 400px) {
        width: auto;
        &-text {
            width: 80px;
        }
        &-name {
            width: 80px;
        }
        &-checkbox {
            width: 140px;
        }
        &-rule {
            width: 40px;
        }
    }
}

.permission-revoke-container {
    padding: 10px;
}

.permission-revoke-content-container {
    padding: 0;
}

.permission-revoke-list-row {
    border-bottom: 1px solid $secondary-dark;
    margin: 20px;
    font-size: 1.1em;
    & p {
        width: 150px;
    }
}

.permission-sw {
    border-bottom: 1px solid $secondary-dark;
    width: 320px;
    margin: 20px;
}
.permission-row {
    width: 40%;
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    border-bottom: 1px solid $secondary-dark;
}

.role-row {
    margin: 30px 20px;
    border-bottom: 1px solid $secondary-dark;
    & p {
        padding-right: 10px;
    }
}

.users-with-permission-container {
  max-height: 450px;
  overflow-y: auto;
  width: 90%;
  margin-left: 50px;
  }

.permissions-sticky-search{
  width:auto;
  background:$light;
  position:sticky;
  top:0;
  opacity:1;
  z-index:1;
}

.rule-number-box {
    display:inline-block;
    color: $primary;
    border: 1px solid $primary;
    border-radius: 10px;
    background-color: $secondary-dark;
    box-shadow: 0 0 5px -1px rgba(0,0,0,0.2);
    vertical-align:middle;
    max-width: 100px;
    padding: 5px;
    padding-left: 15px;
    text-align: center;
  }

